import * as React from 'react';
import * as CSS from 'csstype';

const Privacy = () => {
  return (
    <div>
      <h1>Privacy and Cookie Policy</h1>
      <p>
        We understand how important your personal information is. This privacy notice will inform
        you how Holland & Barrett uses and looks after your personal information when you visit and
        shop with us or use any of our services, use our website, buy products from us, or download
        and interact with our apps. Our website and apps are not intended for children under the age
        of 16, and we do not knowingly collect personal information relating to children. Please be
        aware that this privacy notice may be updated from time to time.
      </p>
      <h2>What is Personal and Sensitive Data?</h2>
      <p>
        Personal data means any information that may be used to identify you on its own or, when
        combined with other information, will enable identification. Sensitive data may include data
        revealing racial or ethnic origin, or data concerning your health. We may collect, use,
        store and transfer different kinds of personal and sensitive data about you including the
        following:
      </p>
      <ul>
        <li>
          Identity Data including first name, last name, username or similar identifier, date of
          birth and gender.
        </li>
        <li>Contact Data including your address, phone number and email address.</li>
        <li>
          Health Data including any information about your physical or mental health from how you
          shop with us or use our services.
        </li>
        <li>
          Technical Data including internet protocol (IP) address, your login data, browser type and
          version, time zone setting and location, browser plug-in types and versions, operating
          system and platform and other technology on the devices you use to access our website,
          apps, and services.
        </li>
        <li>Profile Data including your username and password.</li>
        <li>Usage Data including information about how you use our website, apps, and services.</li>
      </ul>
      <h2>Cookie Policy</h2>
      <p>
        So that you can make full use of the interactive features on our website, your computer,
        mobile phone or other device (all referred to here as device) will need to accept cookies.
      </p>
    </div>
  );
};

export default Privacy;
